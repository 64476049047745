import React from "react"
import GridSpan from "../GridSpan/GridSpan.js"
import Card from "../Card/Card.js"


export default function TeamProfile(props) {

    /* var span_mobile = props.span[0];
    var span_tablet = props.span[1];
    var span_desktop = props.span[2];
    var span_desktopBig = props.span[3];

    var justifyItems = props.justifyItems; */


    return( 
        <GridSpan span={[12,6,6,4]}>
                <Card
                    alignItems="start"
                >
                    <GridSpan span={[2,2,2,2]}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center"
                            }}
                        >
                            <div
                                style={{
                                    width: "152px",
                                    height: "152px",
                                    borderRadius: "100%",
                                    overflow: "hidden"
                                }}
                            >{props.children}</div>
                        </div>
                        <h3
                            style={{
                                textAlign: "center",
                                color: "var(--neutral-01)"
                            }}
                        >{props.name}</h3>
                        <h4
                            style={{
                                textAlign: "center",
                                color: "var(--neutral-05)"
                            }}
                        >{props.title}</h4>
                        <div>
                            <p 
                                className="body-caption"
                                style={{
                                    textAlign: "center",
                                    color: "var(--neutral-04)"
                                }}
                            >all time favorite artist</p>
                            <h5
                                style={{
                                    color: "var(--neutral-02)",
                                    textAlign: "center"
                                }}
                            >{props.allTimeArtist}</h5>
                        </div>
                        <div>
                            <p 
                                className="body-caption"
                                style={{
                                    textAlign: "center",
                                    color: "var(--neutral-04)"
                                }}
                            >recently played</p>
                            <h5
                                style={{
                                    color: "var(--neutral-02)",
                                    textAlign: "center"
                                }}
                            >{props.recentArtist}</h5>
                        </div>
                        
                    </GridSpan>
                </Card>
            </GridSpan>
    )

}
