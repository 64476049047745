import React from "react"
import Layout from "../components/layout"
import TeamProfile from "../components/TeamProfile/TeamProfile.js"
import Img from "gatsby-image"
import GridSpan from "../components/GridSpan/GridSpan"
import Button from "../components/Button/Button"
import Card from "../components/Card/Card"
import { graphql } from 'gatsby'
import {FaPaperPlane} from 'react-icons/fa'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import * as FontAwesome from 'react-icons/fa'

export default function About({data}) {
    return (
        <Layout>
          <GatsbySeo
            title="Meet the Team"
            description="The people working behind the scenes to create awesome tools for artists."
          />
                  <GridSpan span={[12,12,12,12]}>
          <Card>
            <GridSpan span={[2,1,1,1]}>
              <Img
                fluid={data.bench_guitarman.childImageSharp.fluid}
                alt="Streaming sources for the application."
                style={{
                  width: "100%",
                  margin: "auto",
                  justifySelf: "stretch",
                  background: "var(--blue-07)",
                  borderRadius: 8
                }}
              />
            </GridSpan>
            <GridSpan span={[2,1,1,1]} justifyItems="start">
              <h3 style={{textAlign: "left", width: "100%"}}>we're passionate about music</h3>
              <h5 style={{textAlign: "left", color: "var(--neutral-03)"}}>Breaking into the music industry is too hard for musicians — we’re making tools to change that.</h5>
            </GridSpan>
          </Card>
        </GridSpan>
            <TeamProfile
                name="Pablo Olavarrieta"
                title="co-ceo"
                allTimeArtist="Ben Howard"
                recentArtist="Glass Animals"
            >
                <Img 
                    fluid={data.pablo.childImageSharp.fluid}
                    alt="Pablo Olavarrieta"
                />
            </TeamProfile>
            <TeamProfile
                name="Jack Sanders"
                title="co-ceo"
                allTimeArtist="the Rolling Stones"
                recentArtist="Fenton Robinson"
            >
                <Img 
                    fluid={data.jack.childImageSharp.fluid}
                    alt="Jack Sanders"
                />
            </TeamProfile>
            <TeamProfile
                name="Rob Salazar"
                title="artist relations"
                allTimeArtist="Coldplay"
                recentArtist="Project Pat"
            >
                <Img 
                    fluid={data.rob.childImageSharp.fluid}
                    alt="Rob Salazar"
                />
            </TeamProfile>
            <TeamProfile
                name="Sophie Nguyen"
                title="design + social media"
                allTimeArtist="the Marías"
                recentArtist="Clairo"
            >
                <Img 
                    fluid={data.sophie.childImageSharp.fluid}
                    alt="Sohpie Nguyen"
                />
            </TeamProfile>
            <TeamProfile
                name="Chris Gerbino"
                title="software engineer"
                allTimeArtist="Ta-Ku"
                recentArtist="Swell"
            >
                <Img 
                    fluid={data.chris.childImageSharp.fluid}
                    alt="Chris Gerbino"
                />
            </TeamProfile>
            <GridSpan span={[12,6,6,4]}>
                <Card>
                    <GridSpan span={[2,2,2,2]}>
                        <h3>Interested in joining our team?</h3>
                        <Button
                          to="mailto:hello@ampmusic.co"
                          btnType="secondary"
                          icon={<FaPaperPlane/>}
                          style={{
                            justifySelf:"start"
                          }}
                        >
                          get in touch
                        </Button>
                    </GridSpan>
                </Card>
            </GridSpan>
        </Layout>  )
}

export const query = graphql`
query AboutQuery {
  pablo: file(relativePath: { eq: "About/pablo.png" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(maxWidth: 300, maxHeight: 300){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  jack: file(relativePath: { eq: "About/jack.png" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(maxWidth: 300, maxHeight: 300){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  rob: file(relativePath: { eq: "About/rob.png" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(maxWidth: 300, maxHeight: 300){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  sophie: file(relativePath: { eq: "About/sophie.png" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(maxWidth: 300, maxHeight: 300){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  chris: file(relativePath: { eq: "About/chris.png" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(maxWidth: 300, maxHeight: 300){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  bench_guitarman: file(relativePath: { eq: "About/turntable.jpeg" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(maxWidth: 720){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`